const PRODUCT_NAMES = {
    'remote.month': 'Remote access to ioBroker for one month',
    'remote.6months': 'Remote access to ioBroker for 6 months',
    'remote.year': 'Remote access to ioBroker for one year',
    'remote.promote1': 'Remote access to ioBroker for 3 years',
    'remote.promote4': 'Remote access to ioBroker for one year',
    'assistant.month': 'Assistants support for ioBroker for one month',
    'assistant.6months': 'Assistants support for ioBroker for 6 months',
    'assistant.year': 'Assistants support for ioBroker for one year',
    'assistant.promote1': 'Assistants support for ioBroker for 2 years',
    'assistant.promote2': 'Assistants support for ioBroker for 18 months',
    'assistant.promote3': 'Assistants support for ioBroker for one year',
    'link.50': 'Link remote access for 50€',
    'link.100': 'Link remote access for 100€',
    'iobroker.vis.offline': 'Offline iobroker.vis license',
    'iobroker.vis.offline.action': 'Offline iobroker.vis license',
    'iobroker.vis-2-widgets-jaeger': 'Jäger-Design widgets for VIS-2',
    'iobroker.vis-2-widgets-jaeger.action': 'Jäger-Design widgets for VIS-2',
    'iobroker.knx_1000': 'ioBroker.knx with 1000 data points',
    'iobroker.knx_2000': 'ioBroker.knx with 2000 data points',
    'iobroker.knx_3000': 'ioBroker.knx with 3000 data points',
    'iobroker.knx_60000': 'ioBroker.knx with unlimited data points',
    'iobroker.knx.year_1000': 'ioBroker.knx with 1000 data points. Valid one year',
    'iobroker.knx.year_2000': 'ioBroker.knx with 2000 data points. Valid one year',
    'iobroker.knx.year_3000': 'ioBroker.knx with 3000 data points. Valid one year',
    'iobroker.knx.year_60000': 'ioBroker.knx with unlimited data points. Valid one year',
    'iobroker.knx.action_1000': 'ioBroker.knx with 1000 data points. Promote',
    'iobroker.knx.action_2000': 'ioBroker.knx with 2000 data points. Promote',
    'iobroker.knx.action_3000': 'ioBroker.knx with 3000 data points. Promote',
    'iobroker.knx.action_60000': 'ioBroker.knx with unlimited data points. Promote',
};

export default PRODUCT_NAMES;
