import React from 'react';

import { Typography, Box } from '@mui/material';

import __ from '../../utils/i18n';
import { MOBILE_WIDTH } from '../../utils/consts';

const styles = {
    titleSize: {
        fontSize: document.body.clientHeight < 500 || window.document.body.clientWidth < MOBILE_WIDTH ? 20 : undefined,
    },
    subTitle: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    list: {
        textAlign: 'center',
    },
    hint: {
        fontStyle: 'italic',
    },
    comment: {
        fontSize: 10,
    },
    pro: {
        display: 'inline-block',
        marginLeft: 5,
        fontSize: 10,
        fontStyle: 'italic',
        fontWeight: 'bold',
        verticalAlign: 'top',
        cursor: 'default',
    },
    star: {
        marginLeft: 5,
        marginRight: 5,
    },
};

export default function Intro(props) {
    const proHint = __('Only via iobroker.pro and is not for free');

    return <Box
        sx={theme => ({
            ...theme.container[props.style],
            alignItems: 'center',
            padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
            width: '100%',
        })}
    >
        <Typography align="center" component="div">
            <Typography
                paragraph
                sx={theme => theme.containerTitle[props.style]}
                style={styles.titleSize}
            >
                {__('Connect your local ioBroker with Amazon Alexa, Yandex Alisa, IFTTT or just access your ioBroker on the way')}
            </Typography>
        </Typography>
        <Typography variant="body1" component="div">
            <div style={styles.subTitle}>
                {__('Assistants')}
                <span style={styles.star}>*</span>
            </div>
            <ul>
                <li>
                    {__('Control ioBroker devices using assistants')}
                    <ul>
                        <li>Amazon Alexa (ioBroker iot Skill)</li>
                        <li>Google Home</li>
                        <li>Yandex Alisa</li>
                    </ul>
                </li>
                <li>{__('Amazon Alexa Custom Skill to answer your own formulations')}</li>
            </ul>
            <div style={styles.subTitle}>
                {__('Connection to services')}
                <span style={styles.star}>*</span>
            </div>
            <ul>
                <li>{__('Connect ioBroker installation to IFTTT')}</li>
                <li>{__('Send geo-location messages from the Geofency App to ioBroker')}</li>
                <li>{__('Using NightScout with a local server')}</li>
                <li>
                    {__('Android Tasker integration for geolocation and other actions')}
                    .
                </li>
            </ul>
            <div style={styles.subTitle}>
                {__('Remote access')}
                <span style={styles.star}>**</span>
            </div>
            <ul>
                <li>
                    {__('Use of the ioBroker Android or iOS app for remote access to visualizations')}
                    <div style={styles.pro} title={proHint}>pro</div>
                </li>
                <li>
                    {__('Use of the browser for remote access to:')}
                    <ul>
                        <li>{__('Viewing the visualizations')}</li>
                        <li>
                            {__('Editing of the visualizations')}
                            <div style={styles.pro} title={proHint}>pro</div>
                        </li>
                        <li>
                            {__('Configuration via the ioBroker administration interface')}
                            <div style={styles.pro} title={proHint}>pro</div>
                        </li>
                        <li>
                            {__('Editing the scripts and logic')}
                            <div style={styles.pro} title={proHint}>pro</div>
                        </li>
                        <li>
                            {__('Access to camera images (without third-party cloud, only certain models)')}
                            .
                            <span style={styles.star}>***</span>
                        </li>
                    </ul>
                </li>
                <li>{__('To open the door, e.g. if the children have forgotten the key,')}</li>
                <li>{__('Checking the open windows,')}</li>
                <li>
                    {__('Check motion detector')}
                    .
                </li>
            </ul>
            <div style={styles.hint}>
                {__('The usage of ioBroker/Cloud and Alexa connection is free of charge but pro version have some advantages against net version.')}
            </div>
            <div style={styles.comment}>
                <span style={styles.star}>*</span>
                {__('Number of permitted commands without a purchase license limited to 20 per day')}
            </div>
            <div style={styles.comment}>
                <span style={styles.star}>**</span>
                {__('Remote access in the Pro service is only possible with a purchase license')}
            </div>
            <div style={styles.comment}>
                <span style={styles.star}>***</span>
                {__('Information on camera compatibility with ioBroker needs to be verified')}
            </div>
        </Typography>
    </Box>;
}
