import React from 'react';

import {
    Typography,
    Divider,
    Box,
    Button,
    Input,
    IconButton,
} from '@mui/material';

import {
    FileCopy,
    DeleteRounded as DeleteRoundedIcon,
    Delete as IconDelete,
} from '@mui/icons-material';

import { red } from '@mui/material/colors';

import { apiDeleteKey, apiGetKey, apiCreateKey } from '../../../api';
import MainContext from '../../../MainContext';
import __ from '../../../utils/i18n';
import { MOBILE_WIDTH } from '../../../utils/consts';

const styles = {
    divider: {
        width: '100%',
        margin: '20px 0',
    },
    comments: {
        color: red[900],
        fontStyle: 'italic',
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
    },
    keyInput: {
        flex: 1,
    },
    keyButton: {},
};

export default function RemoteAccessContents(props) {
    const context = React.useContext(MainContext);
    const keyRef = React.useRef();
    const [appKey, setAppKey] = React.useState(null); // null=requesting,'' = no key or error

    if (!context.user) {
        return null;
    }

    function readKey() {
        apiGetKey()
            .then(result => setAppKey(Array.isArray(result) && result[0] ? result[0].key : ''))
            .catch(error => {
                setAppKey('');
                context.snackbar(__('Cannot read keys %s', __(error)), 'error', true);
            });
    }

    if (appKey === null) {
        readKey();
    }

    const handleAppKeyDelete = () => apiDeleteKey(appKey)
        .then(() => {
            readKey();
            context.snackbar('Key deleted', 'success');
        })
        .catch(error => context.snackbar(__('Key cannot be deleted %s', __(error)), 'error', true));

    const handleAppKeyGenerate = () => apiCreateKey(0)
        .then(() => {
            readKey();
            context.snackbar('Key generated', 'success');
        })
        .catch(error => {
            context.snackbar(__('Key cannot be generated %s', __(error)), 'error', true);
        });

    const handleAppKeyCopy = () => {
        keyRef.current.children[0].select();
        window.document.execCommand('copy');
        keyRef.current.children[0].focus();
        context.snackbar('Key copied to clipboard', 'success');
    };

    return <Box
        sx={theme => ({
            ...theme.container[props.style],
            padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
        })}
    >
        <Typography paragraph>
            {__('To access your ioBroker instance remotely, you must create the app-key and copy it into the configuration dialog of iobroker.cloud adapter.')}
        </Typography>
        {!appKey && appKey !== null ? <Button onClick={handleAppKeyGenerate} variant="contained" color="primary">
            {__('Generate APP-KEY')}
        </Button> : null}
        {appKey ? <>
            <Divider style={styles.divider} />
            <Typography paragraph>
                {__('You have following APP-KEY:')}
            </Typography>
            <div style={styles.flexContainer}>
                <Input style={styles.keyInput} fullWidth ref={keyRef} value={appKey} />
                <IconButton
                    style={styles.keyButton}
                    onClick={handleAppKeyCopy}
                    aria-label="copy"
                    edge="end"
                    size="small"
                >
                    <FileCopy />
                </IconButton>
            </div>
            <Divider style={styles.divider} />
            <Typography paragraph>
                {__('Copy it into the configuration dialog of iobroker.cloud adapter.')}
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleAppKeyDelete} startIcon={<IconDelete />}>
                <DeleteRoundedIcon />
                {__('Delete APP-KEY')}
            </Button>
        </> : null}
    </Box>;
}
