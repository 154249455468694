import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { lightBlue } from '@mui/material/colors';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
    IconButton,
    ListItemIcon,
} from '@mui/material';

import {
    Close as CloseIcon,
    ExitToApp as ExitToAppIcon,
    Wifi as ConnIcon,
    WifiOff as NoConnIcon,
} from '@mui/icons-material';

// eslint-disable import/no-cycle
import { menuPoints } from '../../App';
import MainContext from '../../MainContext';
import { MOBILE_WIDTH } from '../../utils/consts';
import __ from '../../utils/i18n';
import { apiGetInstanceConnected } from '../../api';

const styles = {
    root: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        margin: 0,
        cursor: 'pointer',
    },
    root2: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        margin: 0,
        cursor: 'pointer',
        marginTop: 'auto',
    },
    em: {
        backgroundColor: '#1a557c',
        color: lightBlue[50],
        paddingLeft: 20,
        paddingRight: 20,
        margin: 0,
        height: 41,
    },
    email: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    closeMenuButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 3,
        height: 41,
        width: 41,
    },
    listItemIcon: {
        transition: 'transform .2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    noConnIcon: {
        color: '#dd9e68',
    },
    connIcon: {
        color: '#67ac58',
    },
};

export default function LeftMenu(props) {
    const context = useContext(MainContext);
    const [showApplications, setShowApplications] = React.useState(null);

    if (!context.user || window.location.pathname === '/www/logout') {
        return null;
    }

    function updateConnection() {
        apiGetInstanceConnected()
            .then(connected => setShowApplications(!!connected))
            .catch(() => setShowApplications(false));
    }

    if (showApplications === null) {
        setShowApplications(false);
        updateConnection();
    }

    const active = window.location.pathname.split('/')[3];

    const menu = Object.keys(menuPoints).map(k => {
        let menuItem = menuPoints[k];
        if (menuItem.pro && context.theme === 'pro') {
            menuItem = menuItem.pro;
            k = menuItem.route;
        }
        const title = __(menuItem.title);

        if (k === 'applications') {
            return <ListItemButton
                style={!showApplications ? styles.appButtonProblem : undefined}
                key={k}
                disableGutters
                divider
                title={showApplications ? __('Go to your ioBroker') : __('No connection established with your ioBroker')}
                aria-label={title}
                component="a"
                href="/"
                sx={theme => theme.leftMenu[props.style].menuItem}
                to={menuItem.link}
            >
                <ListItemText primary={title} style={styles.root} />
                <ListItemIcon
                    style={styles.listItemIcon}
                    onClick={e => {
                        e.preventDefault();
                        updateConnection();
                    }}
                    title={__('Click to check the connection')}
                >
                    {showApplications ? <ConnIcon style={styles.connIcon} /> : <NoConnIcon style={styles.noConnIcon} />}
                </ListItemIcon>
            </ListItemButton>;
        }
        return <ListItemButton
            key={k}
            selected={active === k}
            disableGutters
            divider
            aria-label={title}
            sx={{
                '&.active': theme => theme.leftMenu[props.style].active,
                '&.MuiListItemButton-root': theme => theme.leftMenu[props.style].menuItem,
            }}
            component={NavLink}
            onClick={() => {
                if (window.document.body.clientWidth < MOBILE_WIDTH) {
                    props.onMenuClose();
                }
            }}
            to={`/www/account/${k}`}
        >
            <ListItemText primary={title} style={styles.root} />
        </ListItemButton>;
    });

    return <List
        disablePadding
        key="menu"
        orientation="vertical"
        aria-label="Account menu"
        sx={theme => ({
            borderRight: '1px solid #00000020',
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            ...theme.leftMenu[props.style],
            position: 'relative',
        })}
    >
        <ListItem
            title={context.user.email}
            disableGutters
            aria-label={context.user.email}
            component="div"
            divider
            style={styles.em}
        >
            <ListItemText
                sx={{ '&.ListItemText-primary': styles.email }}
                primary={context.user.email}
            />
        </ListItem>
        <Divider />
        {menu}
        <ListItem
            disableGutters
            component="div"
            divider
            onClick={props.handleLogout}
            style={styles.root2}
        >
            <ExitToAppIcon />
            <ListItemText primary={__('Logout')} style={styles.root} />
        </ListItem>
        <IconButton
            title={__('Hide menu')}
            onClick={() => props.onMenuClose()}
            style={styles.closeMenuButton}
        >
            <CloseIcon />
        </IconButton>
    </List>;
}
