import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/de';

import {
    Typography,
    CircularProgress,
    Table,
    Box,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CardContent,
    Card,
    CardActions,
    Grid,
    Button,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider, TextField,
    Dialog,
    DialogTitle, DialogActions,
    DialogContent,
} from '@mui/material';

import lightBlue from '@mui/material/colors/lightBlue';
import blueGrey from '@mui/material/colors/blueGrey';
import {
    AddShoppingCart as AddShoppingCartIcon,
    PictureAsPdf as IconReceipt,
    RemoveRedEye as IconShow,
    ExpandMore as ExpandMoreIcon,
    Check as CheckIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

import getProductImage from '../../../utils/getProductImage';
import {
    apiGetInvoice,
    apiReadSubscriptions,
    apiReadLicenses,
    apiGetProducts,
    apiConvertLicense,
} from '../../../api';
import MainContext from '../../../MainContext';
import ModalWindow from '../../Pricing/ModalWindow';
import LicenseWindow from './LicenseWindow';
import __ from '../../../utils/i18n';
import getParam from '../../../utils/getParam';

import { MOBILE_WIDTH, ORDER_TIME_LIMIT } from '../../../utils/consts';

const tableStyles = {
    head: {
        backgroundColor: blueGrey[100],
        fontWeight: 'bold',
        fontSize: 14,
    },
    body: {
        fontSize: 14,
    },
};

let requestInvoiceTimeout = null;

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
        flexWrap: 'nowrap',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
    },
    square: {
        position: 'relative',
        width: '100%',
        backgroundColor: lightBlue[300],
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    squareIcon: {
        height: 80,
        width: 80,
        margin: 10,
    },
    squareSmallIcon: {
        width: 31,
        height: 31,

        marginRight: 10,
        marginBottom: -12,
    },
    cardContent: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    cardTitle: {
        padding: 10,
        textAlign: 'center',
        fontSize: '1.4rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    cardAction: {
        justifyContent: 'space-around',
    },
    accordionHead: {
        backgroundColor: lightBlue[800],
        color: 'white',
        paddingLeft: 40,
        height: 50,
        minHeight: '50px !important',
    },
    accordionDetails: {
        padding: 0,
    },
    primary222: {
        backgroundColor: '#FFFFFF50',
        marginBottom: '16px !important',
    },
    addShop: {
        fontSize: 70,
    },
    verticalButtonContainer: {
        '& > span': {
            display: 'block',
        },
        width: '100%',
    },
    cardButton: {

    },
    cardValidTill: {
        fontSize: 10,
        flexGrow: 1,
        textAlign: 'center',
    },
    orderLicenseButton: {
        marginBottom: 20,
    },
    addShopSmall: {
        fontSize: 35,
    },
    noMoreValid: {
        opacity: 0.5,
    },
    uuid: {
        fontSize: 10,
        fontStyle: 'italic',
        marginLeft: 40,
    },
    version: {
        fontSize: 10,
        marginLeft: 8,
    },
    space: {
        marginLeft: 8,
    },
};
// const rows = [
//   createData('Cloud month', '25.11.2018', '25.12.2018', 'Show'),
//   createData('Assistants 6 Moths', '26.12.2018', '26.05.2019', 'Show'),
//   createData('Cloud 6 months', '27.05.2019', '27.11.2019', 'Show')
// ];
export default function Subscriptions(props) {
    const context = React.useContext(MainContext);
    const navigate = useNavigate();

    const handleOrder = () => navigate('/www/pricing');

    const [products, setProducts] = React.useState(null);
    const [requesting, setRequesting] = React.useState('');
    const [licenses, setLicenses] = React.useState(null);
    const [subscriptions, setSubscriptions] = React.useState(null);
    const [showLicense, setShowLicense] = React.useState(null);
    const [remoteValidTill, setRemoteValidTill] = React.useState(Date.now());
    const [assistantValidTill, setAssistantValidTill] = React.useState(Date.now());
    const [showConvertDialog, setShowConvertDialog] = React.useState(null);
    const [uuid, setUuid] = React.useState('');
    const [uuidChangeable, setUuidChangeable] = React.useState(true);

    function updateLicenses(force) {
        // licenses
        if ((force || licenses === null) && context.theme === 'net') {
            setLicenses([]);
            apiReadLicenses()
                .then(result => {
                    result && result.forEach(item => {
                        item.stillValid   = !item.validTill || item.validTill === '0000-00-00 00:00:00' || new Date(item.validTill).getTime() > Date.now();
                        item.activeTillMs = item.validTill && item.validTill !== '0000-00-00 00:00:00' ? new Date(item.validTill).getTime() : null;
                        item.activeTill   = item.activeTillMs ? moment(new Date(item.activeTillMs)).format('D.MM.YYYY HH:mm') : '';
                        item.time         = item.time ? moment(item.time).format('D.MM.YYYY HH:mm') : '';
                        item.convertable  = item.product === 'iobroker.vis' && (item.version === '<2' || item.version === '&lt;2') && !item.uuid;
                        item.version      = (item.version || '').toString().replace('&lt;', '<');
                    });
                    setLicenses(result || []);
                })
                .catch(error => context.snackbar(__('Cannot read: %s', __(error)), 'error', true));
        }

        // subscriptions
        if ((force || subscriptions === null) && context.theme === 'pro') {
            setSubscriptions([]);
            apiReadSubscriptions()
                .then(result => {
                    result && result.forEach(item => {
                        item.stillValid   = !item.validTill || item.validTill === '0000-00-00 00:00:00' || new Date(item.validTill).getTime() > Date.now();
                        item.activeTillMs = item.validTill && item.validTill !== '0000-00-00 00:00:00' ? new Date(item.validTill).getTime() : null;
                        item.activeTill   = item.activeTillMs ? moment(new Date(item.activeTillMs)).format('D.MM.YYYY HH:mm') : '';
                        item.time         = item.time ? moment(item.time).format('D.MM.YYYY HH:mm') : '';
                    });
                    // find validTill Remote and validTill assistant
                    let _remoteValidTill = Date.now();
                    let _assistantValidTill = Date.now();
                    result && result.filter(item => item.stillValid && item.activeTillMs).forEach(item => {
                        if (item.product.startsWith('remote.')) {
                            if (_remoteValidTill < item.activeTillMs) {
                                _remoteValidTill = item.activeTillMs;
                            }
                        } else if (item.product.startsWith('assistant.')) {
                            if (_assistantValidTill < item.activeTillMs) {
                                _assistantValidTill = item.activeTillMs;
                            }
                        }
                    });

                    if (_remoteValidTill > _assistantValidTill) {
                        _assistantValidTill = _remoteValidTill;
                    }
                    setRemoteValidTill(_remoteValidTill);
                    setAssistantValidTill(_assistantValidTill);

                    setSubscriptions(result || []);
                })
                .catch(error => context.snackbar(__('Cannot read: %s', __(error)), 'error', true));
        }
    }

    updateLicenses();

    const openInvoice = invoice => {
        setRequesting(invoice);
        apiGetInvoice(invoice)
            .then(data => {
                // Create an invisible A element
                const a = window.document.createElement('a');
                a.style.display = 'none';
                window.document.body.appendChild(a);

                // Set the HREF to a Blob representation of the data to be downloaded
                a.href = `https://iobroker.${context.theme}:${context.port}/api/v1/${data.url}&download=true`;

                // Use download attribute to set the desired file name
                a.setAttribute('download', `${invoice}.pdf`);

                // Trigger the download by simulating click
                a.click();

                setRequesting('');

                // Cleanup
                window.URL.revokeObjectURL(a.href);
                window.document.body.removeChild(a);
            })
            .catch(e => {
                setRequesting('');
                console.error(e);
                context.snackbar(__('Cannot get invoice: %s', __(e)), 'error', true);
            });
    };

    if (products === null) {
        setProducts(false);
        apiGetProducts()
            .then(data => setProducts(data))
            .catch(error => context.snackbar(__('Cannot read: %s', __(error)), 'error', true));
    }

    if (!products || !context.user) {
        return null;
    }

    if (getParam('invoice') && !requestInvoiceTimeout) {
        const invoice = getParam('invoice');
        requestInvoiceTimeout = setTimeout(() => {
            openInvoice(invoice);
            navigate(window.location.pathname);
        }, 500);
    }

    const data = context.theme === 'net' ? licenses : subscriptions;

    const testerPart = context.user.tester ?
        <Accordion defaultExpanded style={styles.primary222}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={styles.accordionHead}
            >
                <Typography style={styles.heading}>{__('You are the cloud tester!')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography style={styles.heading}>
                    {__('This is an account to test the cloud. You don\'t need a license to access it. Thank you so much! :)')}
                </Typography>
            </AccordionDetails>
        </Accordion>
        : null;

    const convertDialog = showConvertDialog ? <Dialog
        open={!0}
        onClose={() => setShowConvertDialog(null)}
        maxWidth="md"
    >
        <DialogTitle></DialogTitle>
        <DialogContent>
            <div>{__('Do you want to convert your old vis license to the vis-2?')}</div>
            <div>{__('You cannot revert the license back to v1 after conversion!')}</div>
            <br />
            <br />
            <TextField
                variant="standard"
                autoFocus
                fullWidth
                disabled={!uuidChangeable}
                label={__('UUID of ioBroker instance')}
                helperText={__('You can find it in the ioBroker.vis-2 settings')}
                value={uuid}
                onChange={e => setUuid(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button
                variant="contained"
                disabled={uuid.length < 36 || uuid.length > 38}
                onClick={() => {
                    const id = showConvertDialog;
                    setShowConvertDialog(null);
                    apiConvertLicense(id, uuid)
                        .then(() => {
                            context.snackbar('License converted');
                            updateLicenses(true);
                        })
                        .catch(e => context.snackbar(__('Cannot convert license: %s', __(e)), 'error', true));
                }}
                color="primary"
                startIcon={<CheckIcon />}
            >
                {__('Convert')}
            </Button>
            <Button
                variant="outlined"
                onClick={() => setShowConvertDialog(null)}
                startIcon={<CloseIcon />}
            >
                {__('Cancel')}
            </Button>
        </DialogActions>
    </Dialog> : null;

    const NOW_IN_TWO_YEARS = Date.now() + ORDER_TIME_LIMIT;

    const activeSubscriptions = context.theme !== 'net' ?
        <Accordion defaultExpanded={!context.user.tester} style={styles.primary222}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={styles.accordionHead}
            >
                <Typography style={styles.heading}>{__('Active subscriptions')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing="3px">
                    {data.filter(item => item.stillValid).map((item, key) => {
                        const product = products.find(_product => _product.name === item.product);
                        return <Grid item xs={12} sm={4} key={key}>
                            <Card style={styles.square}>
                                <CardContent style={styles.cardContent}>
                                    <div style={styles.cardTitle}>
                                        {item.product}
                                    </div>
                                    <img
                                        src={getProductImage(item.product)}
                                        style={styles.squareIcon}
                                        alt="cloud"
                                    />
                                    <Divider />
                                </CardContent>
                                <CardActions style={styles.cardAction}>
                                    <div style={styles.cardValidTill}>
                                        {item.activeTill ?
                                            `${__('Active Till')} ${item.activeTill}`
                                            : null}
                                    </div>
                                    {context.theme === 'net' ? <Button
                                        variant="contained"
                                        style={styles.cardButton}
                                        onClick={() => setShowLicense({ text: item.json, name: item.product })}
                                    >
                                        {__('Show')}
                                    </Button> : <ModalWindow
                                        navigate={navigate}
                                        style={styles.cardButton}
                                        label={__('Reorder')}
                                        product={product}
                                        onGenerated={() => updateLicenses(true)}
                                        orderEnabled={
                                            item.product.startsWith('remote.') ?
                                                NOW_IN_TWO_YEARS > remoteValidTill :
                                                (item.product.startsWith('assistant') ? NOW_IN_TWO_YEARS > assistantValidTill : true)
                                        }
                                        {...props}
                                    /> }
                                </CardActions>
                            </Card>
                        </Grid>;
                    })}
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleOrder}
                            style={{ ...styles.verticalButtonContainer, flexDirection: 'column' }}
                        >
                            <AddShoppingCartIcon style={styles.addShop} />
                            {context.theme === 'net' ? __('Order new license') : __('Order new subscription')}
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
        : null;

    const anyConvertable = data.find(item => item.convertable);

    const orders = <TableContainer key="orders">
        <Table style={styles.table} aria-label="simple table" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ '&.MuiTableCell-head': tableStyles.head }}>{__('Product')}</TableCell>
                    <TableCell sx={{ '&.MuiTableCell-head': tableStyles.head }} align="right">{__('Order date')}</TableCell>
                    <TableCell sx={{ '&.MuiTableCell-head': tableStyles.head }} align="right">{__('Valid till')}</TableCell>
                    {context.theme === 'net' ? <TableCell sx={{ '&.MuiTableCell-head': tableStyles.head }} align="center">{__('License')}</TableCell> : null}
                    <TableCell sx={{ '&.MuiTableCell-head': tableStyles.head }} align="center">{__('Receipt')}</TableCell>
                    {anyConvertable ? <TableCell sx={{ '&.MuiTableCell-head': tableStyles.head }} align="center">{__('Convert')}</TableCell> : null}
                </TableRow>
            </TableHead>
            <TableBody>
                {data
                    .sort((a, b) => (a.time < b.time ? 1 : -1))
                    .map((row, i) => <TableRow
                        key={`${row.product}:${i}`}
                        style={!row.stillValid ? styles.noMoreValid : undefined}
                    >
                        <TableCell sx={{ '&.MuiTableCell-body': tableStyles.body }} component="th" scope="row">
                            <img
                                src={getProductImage(row.product)}
                                style={styles.squareSmallIcon}
                                alt="cloud"
                            />
                            {row.product}
                            <span style={styles.version}>{(row.version || '').replace('&lt;', '<').replace('&gt;', '>')}</span>
                            {row.uuid ? <>
                                <br />
                                <span style={styles.uuid}>
                                    {__('Serial')}:
                                    <span style={styles.space}>{row.uuid}</span>
                                </span>
                            </> : null}
                            {row.datapoints ? <>
                                <br />
                                <span style={styles.uuid}>
                                    {__('Data points')}:
                                    <span style={styles.space}>{row.datapoints}</span>
                                </span>
                            </> : null}
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-body': tableStyles.body }} align="right">{row.time}</TableCell>
                        <TableCell sx={{ '&.MuiTableCell-body': tableStyles.body }} align="right">{row.activeTill}</TableCell>
                        {context.theme === 'net' ? <TableCell sx={{ '&.MuiTableCell-body': tableStyles.body }} align="center">
                            <IconButton
                                size="small"
                                variant="contained"
                                // color="default"
                                onClick={() => setShowLicense({ text: row.json, name: row.product })}
                            >
                                <IconShow />
                            </IconButton>
                        </TableCell> : null}
                        <TableCell sx={{ '&.MuiTableCell-body': tableStyles.body }} align="center">
                            {row.invoice === 'free' ? '--' :
                                (requesting === row.invoice ?
                                    <CircularProgress />
                                    :
                                    <IconButton
                                        disabled={!!requesting}
                                        size="small"
                                        variant="contained"
                                        // color="default"
                                        target="_blank"
                                        onClick={() => openInvoice(row.invoice)}
                                    >
                                        <IconReceipt />
                                    </IconButton>)}
                        </TableCell>
                        {anyConvertable ? <TableCell sx={{ '&.MuiTableCell-body': tableStyles.body }} align="center">
                            {row.convertable ? <Button
                                variant="contained"
                                title={__('You can convert this license to v2')}
                                onClick={() => {
                                    setUuid(row.uuid || '');
                                    setUuidChangeable(!row.uuid);
                                    setShowConvertDialog(row.id);
                                }}
                            >
                                {__('Convert to v2')}
                            </Button> : null}
                        </TableCell> : null}
                    </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>;

    const orderHistory = context.theme !== 'net' ?
        <Accordion defaultExpanded={!context.user.tester} style={styles.primary222}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={styles.accordionHead}
            >
                <Typography style={styles.heading}>{context.theme === 'net' ? __('Licenses') : __('Order history')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={styles.accordionDetails}>
                {orders}
            </AccordionDetails>
        </Accordion>
        :
        [
            <Button
                key="orderButton"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleOrder}
                style={styles.orderLicenseButton}
            >
                <AddShoppingCartIcon style={styles.addShopSmall} />
                {__('Order new license')}
            </Button>,
            orders,
        ];

    return <Box style={styles.content}>
        {convertDialog}
        {testerPart}
        {activeSubscriptions}
        {orderHistory}
        {showLicense ? <LicenseWindow
            onClose={() => setShowLicense(null)}
            text={showLicense.text}
            name={showLicense.name}
        /> : null}
    </Box>;
}
