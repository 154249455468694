import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Grid,
    Tabs,
    Tab,
    LinearProgress,
    Typography,
} from '@mui/material';

import { MOBILE_WIDTH } from '../../utils/consts';
import CreateAccountForm from './CreateAccountForm';
import LoginForm from './LoginForm';
import CreateAccountFormTab from './CreateAccountFormTab';
import LoginFormTab from './LoginFormTab';

import MainContext from '../../MainContext';
import __ from '../../utils/i18n';
import getParam from '../../utils/getParam';

let lastRedirect = null;

const styles = {
    cont: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: 5,
    },
    formText: {
        maxWidth: 350,
    },
    formButton: {
        marginTop: 8,
    },
    hidden: {
        display: 'none',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
    },
};

export default function Login(props) {
    const navigate = useNavigate();
    const context = React.useContext(MainContext);
    const [width, setWidth] = React.useState(1000);

    const updateWindowDimensions = () => setWidth(document.body.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        updateWindowDimensions();
        // returned function will be called on a component unmounting
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => setValue(newValue);
    const [shift, setShift] = React.useState(true);
    const handleShift = (/* event */) => setShift(!shift);

    if (context.user) {
        if (!lastRedirect || Date.now() - lastRedirect > 2000) {
            lastRedirect = Date.now();
            setTimeout(() => {
                const redirect = context.loginRedirect || getParam('redirect');

                context.loginRedirect && context.setLoginRedirect(null);

                if (redirect && !redirect.endsWith('/login')) {
                    if (redirect.startsWith('/www')) {
                        navigate(redirect);
                    } else {
                        window.location = redirect;
                    }
                } else {
                    navigate('/www/account/appkeys');
                }
            }, 200);
        }

        return null;
    }

    if (context.user === null) { // still requesting
        return <LinearProgress />;
    }

    if (width > MOBILE_WIDTH) {
        return <Grid
            container
            sx={theme => ({
                ...theme.container[props.style],
                width: '100%',
                alignItems: 'stretch',
                padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
            })}
        >
            <Grid item xs={12} style={styles.cont}>
                <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    spacing={0}
                    sx={theme => ({
                        backgroundColor: theme.loginForm[props.style].backgroundColor,
                    })}
                    style={styles.cont}
                >
                    <Grid item xs={6} style={{ padding: 0 }}>
                        <Box
                            sx={theme => ({
                                ...theme.loginForm[props.style].formLeft,
                                height: theme.loginForm[props.style].height,
                                ...(!shift ? styles.hidden : undefined),
                            })}
                        >
                            <Typography
                                sx={theme => ({
                                    ...theme.loginForm[props.style].title,
                                    paddingTop: '16px',
                                })}
                            >
                                {__('Sign In')}
                            </Typography>
                            <LoginForm index={value} />
                        </Box>
                    </Grid>
                    <Grid item xs={6} style={{ padding: 0 }}>
                        <Box
                            sx={theme => ({
                                ...theme.loginForm[props.style].formRight,
                                height: theme.loginForm[props.style].height,
                                ...(shift ? styles.hidden : undefined),
                            })}
                        >
                            <Typography
                                sx={theme => ({
                                    ...theme.loginForm[props.style].title,
                                    paddingTop: '16px',
                                })}
                            >
                                {__('Create account')}
                            </Typography>
                            <CreateAccountForm index={value} />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={theme => ({
                            ...theme.loginForm[props.style].overlay,
                            borderRadius: '5px',
                            padding: 0,
                            ...(shift ? theme.loginForm[props.style].overlayShift : undefined),
                        })}
                    >
                        <Box
                            sx={theme => ({
                                ...theme.loginForm[props.style].overlayContainer,
                                ...(shift ? theme.loginForm[props.style].overlayContainerShift : undefined),
                            })}
                        >
                            <Box sx={theme => theme.loginForm[props.style].overlayCont}>
                                <Typography
                                    sx={theme => ({
                                        ...theme.loginForm[props.style].title,
                                        paddingTop: '16px',
                                    })}
                                >
                                    {__('Hello, Friend!')}
                                </Typography>
                                <Typography style={styles.formText}>
                                    {__('Enter your personal details and start journey with us')}
                                </Typography>
                                <Button style={styles.formButton} onClick={handleShift} variant="contained">
                                    {__('or sign In')}
                                </Button>
                            </Box>
                            <Box sx={theme => theme.loginForm[props.style].overlayCont}>
                                <Typography
                                    sx={theme => ({
                                        ...theme.loginForm[props.style].title,
                                        paddingTop: '16px',
                                    })}
                                >
                                    {__('Welcome Back!')}
                                </Typography>
                                <Typography style={styles.formText}>
                                    {__('To keep connected with us please login with your personal info')}
                                </Typography>
                                <Button style={styles.formButton} onClick={handleShift} variant="contained">
                                    {__('or sign Up')}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>;
    }

    return <Grid
        container
        sx={theme => ({
            ...theme.container[props.style],
            width: '100%',
            alignItems: 'stretch',
            padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
        })}
    >
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label={__('Sign In')} />
            <Tab label={__('Sign Up')} />
        </Tabs>
        <LoginFormTab index={value} />
        <CreateAccountFormTab index={value} onChangeTab={(/* email, password */) => handleShift()} />
    </Grid>;
}
