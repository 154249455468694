import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Grid,
    List,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    Typography,
    Checkbox,
    Tooltip,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Box,
} from '@mui/material';

import {
    indigo,
    blueGrey,
} from '@mui/material/colors';

import {
    InfoOutlined as IconInfo,
    Close as IconClose,
} from '@mui/icons-material';

import ModalWindow from './ModalWindow';
import getProductImage from '../../utils/getProductImage';
import __ from '../../utils/i18n';

const styles = {
    priceCont: {
        position: 'relative',
        padding: '0 !important',
    },
    root: {
        marginRight: '20px',
        marginBottom: '20px',
        marginTop: '40px',
        padding: '10px',
        paddingBottom: 0,
    },
    listItem: {
        padding: 0,
        margin: 0,
        fontSize: '.8rem',
    },
    listItemBold: {
        fontWeight: 'bold',
    },
    check: {
        padding: 0,
        margin: 0,
        marginRight: 10,
    },
    priceComment: {
        marginLeft: 8,
        fontSize: 10,
        fontWeight: 'normal',
    },
    priceMonth: {
        color: indigo[900],
        textAlign: 'center',
        fontSize: 12,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    squareIcon: {
        height: 30,
        width: 'auto',
        marginBottom: -11,
        marginRight: 10,
    },
    cnc: {
        display: 'flex',
        flexDirection: 'column',
    },
    cncBtn: {
        marginTop: 'auto',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    empty30: {
        height: 30,
    },
    iconInfo: {
        width: 14,
        height: 14,
        marginLeft: 5,
        verticalAlign: 'middle',
    },
    clickable: {
        cursor: 'pointer',
    },
    infoContent: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    space: {
        marginLeft: 8,
    },
};

export default function PriceCard(props) {
    const navigate = useNavigate();

    const [imageSrc, setImageSrc] = React.useState(getProductImage(props.product.name));
    const [showDescription, setShowDescription] = React.useState('');

    let list = props.additionalListItems ? props.additionalListItems.map((feature, key) => <ListItem alignItems="flex-start" key={`1_${key}`} style={styles.listItem}>
        <ListItemText
            style={{
                ...styles.listItem,
                ...(feature.marked ? styles.listItemBold : undefined),
            }}
            sx={{
                '&.ListItemText-primary': {
                    ...styles.listItem,
                    ...(feature.marked ? styles.listItemBold : undefined),
                },
            }}
        >
            <Checkbox checked disabled edge="start" style={styles.check} />
            {feature.title}
        </ListItemText>
    </ListItem>) : [];

    list = list.concat(props.product.localData ? props.product.localData.list.map((feature, key) => <ListItem
        alignItems="flex-start"
        key={`2_${key}`}
        style={styles.listItem}
    >
        <Tooltip title={feature.description ? __('Click here to get more information') : ''}>
            <ListItemText
                onClick={() => feature.description && setShowDescription(feature.description)}
                style={{
                    ...styles.listItem,
                    ...(feature.marked ? styles.listItemBold : undefined),
                    ...(feature.description ? styles.clickable : undefined),
                }}
                sx={{
                    '&.ListItemText-primary': {
                        ...styles.listItem,
                        ...(feature.marked ? styles.listItemBold : undefined),
                    },
                }}
            >
                <Checkbox checked disabled edge="start" style={styles.check} />
                {__(feature.title)}
                {feature.description ? <IconInfo style={styles.iconInfo} /> : null}
            </ListItemText>
        </Tooltip>
    </ListItem>) : []);

    const descriptionDialog = showDescription ? <Dialog
        open={!0}
    >
        <DialogTitle>
            <IconInfo />
            {__('Information')}
        </DialogTitle>
        <DialogContent style={styles.infoContent}>
            <p>{__(showDescription)}</p>
        </DialogContent>
        <DialogActions>
            <Button
                autoFocus
                onClick={() => setShowDescription('')}
                startIcon={<IconClose />}
                variant="contained"
            >
                {__('Ok')}
            </Button>
        </DialogActions>
    </Dialog> : null;

    let isBest = props.product.best ? <Box
        sx={theme => ({
            color: '#FFF',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            textAlign: 'center',
            marginRight: 20,
            height: 20,
            padding: '10px 20px',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            ...theme.priceCard[props.style].bestValue,
        })}
    >
        {__('Best Value')}
    </Box> : null;

    let normalPrice;
    if (props.product.action_till) {
        let actionTill;
        if (props.product.action_till.startsWith('{')) {
            try {
                const data = JSON.parse(props.product.action_till);
                actionTill = data.end;
                normalPrice = parseFloat(data.normal || 0) || 0;
            } catch (e) {
                console.error(`Cannot parse action data: ${props.product.action_till}`);
            }
        } else {
            actionTill = props.product.action_till;
        }
        if (actionTill) {
            try {
                const date = new Date(actionTill);
                if (date.getTime() >= Date.now()) {
                    isBest = <Box
                        sx={theme => ({
                            color: '#FFF',
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            textAlign: 'center',
                            marginRight: 20,
                            height: 20,
                            padding: '10px 20px',
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,
                            ...theme.priceCard[props.style].bestValue,
                            ...theme.priceCard[props.style].actionTill,
                        })}
                    >
                        {__('Action till %s', date.toLocaleDateString())}
                    </Box>;
                }
            } catch (e) {
                // ignore
            }
        }
    }

    return <Grid item xs={12} md={4} style={styles.priceCont}>
        {isBest}
        {descriptionDialog}
        <Card
            sx={isBest ? theme => ({
                marginRight: 20,
                marginBottom: 20,
                padding: 10,
                paddingBottom: 0,
                backgroundColor: '#c6edff',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                ...theme.priceCard[props.style],
            }) : styles.root}
            variant="outlined"
            title={props.explanationOfDisability}
        >
            <CardContent style={styles.cardContent}>
                <Typography
                    gutterBottom
                    className={props.product.best ? theme => ({
                        fontSize: '1.4rem',
                        color: 'white',
                        ...theme.priceCard[props.style].bestTitle,
                    }) : theme => ({
                        fontSize: '1.4rem',
                        ...theme.priceCard[props.style].title,
                    })}
                    component="div"
                >
                    <img
                        src={imageSrc}
                        onError={() => imageSrc !== 'products/default.png' && setImageSrc('products/default.png')}
                        style={styles.squareIcon}
                        alt={props.product.name}
                    />
                    {props.product.localData && props.product.localData.title ? __(props.product.localData.title) : __(props.product.name)}
                </Typography>
                <List>{list}</List>
                <Typography
                    gutterBottom
                    sx={theme => ({
                        fontWeight: 700,
                        fontSize: '1.4rem',
                        textAlign: 'center',
                        justifySelf: 'center',
                        ...theme.priceCard[props.style].price,
                        color: props.product.best ?
                            (props.style === 'net' ? '#FFFFFF' : '#0d47a1') :
                            (props.style === 'net' ? indigo[900] : blueGrey[500]),
                    })}
                    component="div"
                >
                    {props.product.price.toFixed(2).replace('.', ',')}
                    €
                    {props.product.price ? <span style={styles.priceComment}>{__('VAT included')}</span> : null}
                    {normalPrice ? <span
                        style={{
                            color: props.style === 'net' ? '#FF2222' : '#FF2222',
                            paddingLeft: 10,
                            fontSize: 16,
                        }}
                    >
                        {__('Sale')}
                        <span style={styles.space}>{Math.round(((normalPrice - props.product.price) / normalPrice) * 100)}</span>
                        %
                    </span> : null}
                </Typography>
                {
                    props.product.months > 1 && props.product.price
                        ?
                        <Typography gutterBottom style={styles.priceMonth} component="div">
                            {__('Only')}&nbsp;
                            {(props.product.price / props.product.months).toFixed(2).toString().replace('.', ',')}
                            {__('€ month')}
                        </Typography>
                        :
                        <div style={styles.empty30} />
                }
                <ModalWindow
                    navigate={navigate}
                    uuid={props.product.localData ? props.product.localData.uuid : ''}
                    {...props}
                />
            </CardContent>
        </Card>
    </Grid>;
}
