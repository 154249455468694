import React from 'react';

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/de';

import { Box } from '@mui/material';

import { apiGetStatisticsCloud, apiGetStatisticsIot } from '../../../api';
import MainContext from '../../../MainContext';
import __ from '../../../utils/i18n';
import { MOBILE_WIDTH } from '../../../utils/consts';

const VALIDATE = {
    status: (value, data) => !data.enabled || value === 'CONFIRMED',
    enabled: value => !!value,
    emailVerified: (value, data) => !data.enabled || !!value,
    iotDevice: (value, data) => !data.enabled || !!value,
    validTill: date => date && new Date(date).getTime() > Date.now(),
    urlKey: (value, data) => value && value === data.urlKeyService,
    urlKeyService: (value, data) => value && value === data.urlKey,
    firstUse: value => !!value,
    monthCount: value => value < 20000,
    dayCount: value => value < 1000,
    blockedTill: value => !value,
};

const VALUE_ERRORS = {
    emailVerified: 'Confirm email address',
    status: 'Confirm email address',
    validTill: 'Purchase new subscription',
    enabled: 'Try to create IoT credentials anew or contact support',
    firstUse: 'You should communicate with Alexa or Google Home at least one time',
    iotDevice: 'Try to create IoT credentials anew or contact support',
    monthCount: 'Too many commands sent in this month. You have invalid configuration with high probability.',
    dayCount: 'Too many commands sent today. You have invalid configuration with high probability.',
    urlKey: 'This key must be equal with service key. Try to create IoT credentials anew.',
    urlKeyService: 'This key must be equal with URL key. Try to create IoT credentials anew.',
    blockedTill: 'Too big amount of data. Please wait.',
};

const styles = {
    valueRow: {
        fontFamily: 'Arial',
    },
    valueName: {
        width: 250,
        display: 'inline-block',
        fontWeight: 'bold',
        marginRight: 16,
    },
    value: {
        marginLeft: 8,
    },
    valueOK: {
        color: '#009f00',
    },
    valueError: {
        color: '#c40000',
    },
    errorDescription: {
        color: '#6e5b5b',
        marginLeft: 8,
        fontSize: 'smaller',
    },
};

export default function Diagnostics(props) {
    const context = React.useContext(MainContext);

    const [iot, setIot] = React.useState({});
    const [cloud, setCloud] = React.useState({});

    if (!iot.requested && context.theme === 'pro') {
        setIot({ requested: true });
        apiGetStatisticsIot()
            .then(result => setIot({ requested: true, received: true, ...result }))
            .catch(error => {
                if (error !== 'Authentication required.') {
                    context.snackbar(__('Cannot read: %s', __(error)), 'error', true);
                }
            });
    }
    if (!cloud.requested) {
        setCloud({ requested: true });
        apiGetStatisticsCloud()
            .then(result => setCloud({ requested: true, received: true, ...result }))
            .catch(error => {
                if (error !== 'Authentication required.') {
                    context.snackbar(__('Cannot read: %s', __(error)), 'error', true);
                }
            });
    }

    const usageField = ['dayCount', 'monthCount', 'firstUse', 'lastCommand'];
    const userField = ['contractAck', 'emailAck', 'lastLoginError', 'lastSocketConnection', 'lastWebConnection', 'privacyAck'];

    function renderLine(data, rowKey) {
        if (rowKey === 'requested' || rowKey === 'received') {
            return null;
        }
        let rowValue = data[rowKey];

        if (rowKey === 'lastCommand' || rowKey === 'lastSocketConnection' || rowKey === 'lastWebConnection') {
            rowValue = rowValue ? `${moment(rowValue).format('D.MM.YYYY HH:mm:ss')} (${moment(rowValue).fromNow()})` : '--';
        }
        rowValue = rowValue === null ? 'null' : (rowValue === undefined ? '__' : rowValue);
        const noError = VALIDATE[rowKey] && VALIDATE[rowKey](data[rowKey], data);

        return <div key={rowKey} style={styles.valueRow}>
            <div style={styles.valueName}>
                {__(rowKey)}
                :
            </div>
            <span
                style={{
                    ...styles.value,
                    ...(VALIDATE[rowKey] ? (noError ? styles.valueOK : styles.valueError) : undefined),
                }}
            >
                {rowValue && typeof rowValue === 'string' &&
                    rowValue.length === 24 && // '2024-02-03T22:59:59.000Z'
                    rowValue.endsWith('Z') ? moment(rowValue).format('D.MM.YYYY HH:mm:ss') : rowValue.toString()}
            </span>
            {data.requested && !noError && VALUE_ERRORS[rowKey] ? <span style={styles.errorDescription}>{__(VALUE_ERRORS[rowKey])}</span> : null}
        </div>;
    }

    if (!context.user) {
        return null;
    }

    const usage = iot.received && iot.enabled && context.theme === 'pro' ?
        <>
            <Box sx={theme => theme.containerTitle[props.style]}>
                {__('Usage')}
            </Box>
            <div>
                {usageField.map(rowKey => renderLine(iot, rowKey))}
            </div>

            <Box sx={theme => theme.containerTitle[props.style]}>
                {`${__('Subscription IoT')} (pro)`}
            </Box>
            <div style={styles.valueRow}>
                <div style={styles.valueName}>
                    {__('Valid till')}
                    :
                </div>
                <span
                    style={{
                        ...styles.value,
                        ...(iot.requested && VALIDATE.validTill ? (VALIDATE.validTill(iot.validTill, iot) ? styles.valueOK : styles.valueError) : undefined),
                    }}
                >
                    {moment(iot.validTill).format('D.MM.YYYY HH:mm:ss')}
                </span>
                {!VALIDATE.validTill(iot.validTill, iot) && VALUE_ERRORS.validTill ? <span style={styles.errorDescription}>{__(VALUE_ERRORS.validTill)}</span> : null}
            </div>
        </> : null;

    const user = { requested: true, received: true, ...context.user };

    return <Box
        sx={theme => ({
            ...theme.container[props.style],
            padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
        })}
    >
        <Box sx={theme => theme.containerTitle[props.style]}>
            {__('User info')}
        </Box>
        <div>
            {Object.keys(user).map(rowKey => {
                if (!userField.includes(rowKey) || (rowKey === 'lastLoginError' && !context.user[rowKey])) {
                    return null;
                }

                return renderLine(user, rowKey);
            })}
        </div>

        {usage}

        {iot.received ? <Box sx={theme => theme.containerTitle[props.style]}>
            {__('IoT info')}
        </Box> : null}
        <div>
            {iot.received && iot.enabled ? Object.keys(iot).map(rowKey => {
                if (context.theme === 'pro') {
                    if (usageField.includes(rowKey) || rowKey === 'validTill') {
                        return null;
                    }
                }

                return renderLine(iot, rowKey);
            }) : null}
        </div>

        {cloud.received ? <Box sx={theme => theme.containerTitle[props.style]}>
            {__('Remote access info')}
        </Box> : null}
        <div>
            {cloud.received ? Object.keys(cloud).map(rowKey => {
                if ((rowKey === 'blockedTill' ||
                    rowKey === 'blockedBecause' ||
                    rowKey === 'blockedOversize' ||
                    rowKey === 'blockedCount') && !cloud[rowKey]) {
                    return null;
                }

                return renderLine(cloud, rowKey);
            }) : null}
        </div>
    </Box>;
}
