import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';

import {
    apiCreateLicense,
    apiCreateSubscription,
    apiGetPaypalAccount,
    apiGetPaypalToken,
} from '../../api';
import __ from '../../utils/i18n';
import PRODUCT_NAMES from './Products';

const PAYPAL_SERVER = 'https://paypal.iobroker.in';

function getProductDescription(name) {
    return PRODUCT_NAMES[name] || name;
}

function log(text) {
    return new Promise(resolve => {
        let timeout = setTimeout(() => {
            console.error('Cannot write log: timeout');
            timeout = null;
            resolve && resolve();
            resolve = null;
        }, 1000);

        try {
            fetch(`${PAYPAL_SERVER}?log=${encodeURIComponent(text)}`)
                .then(() => {
                    timeout && clearTimeout(timeout);
                    timeout = null;
                    resolve && resolve();
                    resolve = null;
                })
                .catch(error => {
                    timeout && clearTimeout(timeout);
                    timeout = null;
                    console.error(`Cannot write log: ${error}`);
                    resolve && resolve();
                    resolve = null;
                });
        } catch (e) {
            timeout && clearTimeout(timeout);
            timeout = null;
            console.error(`Cannot write log_: ${e}`);
            resolve && resolve();
            resolve = null;
        }
    });
}

function PayPalButtonIO(props) {
    const [paypal, setPaypal] = React.useState(null);

    if (paypal === null) {
        setPaypal(false);
        apiGetPaypalAccount()
            .then(_paypal => setPaypal(_paypal));
    }

    if (!paypal) {
        return null;
    }

    return <PayPalButton
        options={{
            clientId: paypal.clientId,
            debug: false, // paypal.mode === 'sandbox',
            currency: 'EUR',
            disableFunding: 'credit,card,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
        }}
        createOrder={(data, actions) => {
            console.log(`[${new Date().toISOString()}]Create order`);
            const description = getProductDescription(props.product.name);

            return new Promise(resolve => {
                props.onProgress(true, () =>
                    resolve(actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: props.product.price,
                                currency_code: 'EUR',
                                breakdown: {
                                    item_total: {
                                        value: (props.product.price - props.vat).toFixed(2),
                                        currency_code: 'EUR',
                                    },
                                    tax_total: {
                                        value: props.vat,
                                        currency_code: 'EUR',
                                    },
                                },
                            },
                            description,
                        }],
                        // application_context: {shipping_preference: 'NO_SHIPPING'}
                    })));
            });
        }}
        onApprove={(data, actions) => {
            console.log(`[${new Date().toISOString()}] approved => capture`);

            let token; // token from iob server
            let details; // paypal response
            // Capture the funds from the transaction
            return new Promise(resolve => {
                props.onProgress(true, () => resolve());
            })
                .then(() => apiGetPaypalToken())
                .then(_token => {
                    token = _token || { token: '', user: 'unknown' };
                    return log(`Preparation: ${props.product.name}, ltype: ${props.product.type}, email: ${token.user}, Agent: ${window.navigator && window.navigator.userAgent}`);
                })
                .then(() => actions.order.capture())
                .then(_details => {
                    details = _details;
                    return log(`User: ${token.user}, token: ${token.token}, PAYPAL response: ${JSON.stringify(details)}`);
                })
                .then(() => {
                    const transactionID = details &&
                        details.purchase_units &&
                        details.purchase_units[0] &&
                        details.purchase_units[0].payments.captures &&
                        details.purchase_units[0].payments.captures[0] &&
                        details.purchase_units[0].payments.captures[0].id;

                    const address = JSON.parse(JSON.stringify(props.address));
                    delete address.disabled;
                    delete address.eu;

                    if (transactionID) {
                        console.log(`[${new Date().toISOString()}] Transaction completed by ${JSON.stringify(details)}`);
                        let promise;
                        if (props.cloud === 'pro') {
                            promise = apiCreateSubscription({
                                token: token.token,
                                comment: props.address.comment || '',
                                address,
                                transactionID,
                                product: props.product.name,
                            });
                        } else {
                            const jsonData = {
                                token: token.token,
                                comment: address.comment || '',
                                address,
                                transactionID,
                                product: props.product.name,
                            };
                            if (props.uuid) {
                                jsonData.uuid = props.uuid;
                            }
                            promise = apiCreateLicense(jsonData);
                        }

                        return promise
                            .then(_data => {
                                console.log(JSON.stringify(_data));
                                if (_data && _data.error) {
                                    props.onFinish({
                                        progress: false,
                                        error: `${__('Cannot generate license. Please contact support info@iobroker.net')}: ${_data.error}`,
                                        firefox: false,
                                    });
                                } else {
                                    props.onFinish({
                                        progress: false,
                                        open: false,
                                        success: true,
                                    });
                                }
                            })
                            .catch(e => {
                                console.error(`Set progress false1: ${e}`);
                                props.onFinish({
                                    open: false,
                                    progress: false,
                                    error: __('Cannot generate license. Please contact support info@iobroker.net'),
                                    firefox: false,
                                });
                            });
                    }

                    console.error(`[${new Date().toISOString()}] Transaction completed by ${JSON.stringify(details)}`);

                    props.onFinish({
                        open: false,
                        progress: false,
                        error: __('Something went wrong.. Please contact support info@iobroker.net'),
                        firefox: false,
                    });

                    return log(`ERROR PAYPAL payment: (User: ${token.user}), error: no transaction ID: ${JSON.stringify(details)}`);
                })
                .catch(e => {
                    props.onFinish({
                        progress: false,
                        error: __('Something went wrong. Please contact support info@iobroker.net'),
                        firefox: window.navigator.userAgent.toLowerCase().includes('firefox'),
                    });

                    console.error(`Error by paypal payment 1: ${e}`);

                    return log(`ERROR PAYPAL payment: (User: ${token.user}), Firefox: ${window.navigator.userAgent.toLowerCase().includes('firefox')},  error: ${JSON.stringify(e)} ${e.toString()}`);
                });
        }}
        onCancel={(/* data */) => {
            props.onFinish({
                open: false,
                progress: false,
                error: '',
                firefox: false,
            });
        }}
        onError={err => {
            console.error(`Paypal error: ${err}`);
            props.onFinish({
                open: false,
                progress: false,
                error: __('Cannot generate license. Please contact support info@iobroker.net'),
                firefox: false,
            });

            return log(`ERROR PAYPAL payment_: (User: ${paypal.clientId}), error: ${JSON.stringify(err)} ${err.toString()}`);
        }}
        onButtonReady={() => props.onReady && props.onReady()}
        // onShippingChange={(data, actions) => actions.resolve()}
    />;
}

export default PayPalButtonIO;
