import React from 'react';
import { Box, Typography } from '@mui/material';

import { getLang } from '../../utils/i18n';
import getParam from '../../utils/getParam';
import { MOBILE_WIDTH } from '../../utils/consts';

const styles = {
    tabs: {
        background: 'white',
    },
    title2: {

    },
    spaces: {
        marginLeft: 8,
        marginRight: 8,
    },
    spacesLast: {
        marginRight: 8,
    },
    spaceFirst: {
        marginLeft: 8,
    },
};

export default function PrivacyPolicy(props) {
    let lang = getParam('lang');

    if (lang !== 'en' && lang !== 'de' && lang !== 'ru') {
        lang = null;
    }

    lang = lang || getLang();

    let text;
    switch (lang) {
        case 'de':
            text = <>
                <Typography sx={theme => theme.containerTitle[props.style]}>Datenschutz-Bestimmungen.</Typography>
                <Typography variant="body1" component="div">
                    <Typography variant="h5" style={styles.title2}>
                        ioBroker.cloud & ioBroker.iot Datenschutzbestimmungen
                    </Typography>
                    <Typography paragraph>
                        Wir schätzen Deine Privatsphäre, also wollen wir explizit erklären, welche Art von personenbezogenen Daten beteiligt sind, wenn Du mit der
                        <b style={styles.spaces}>ioBroker.cloud</b>
                        interagierst.
                        <br />
                        <br />
                        Diese Datenschutzerklärung gilt für das gesamte
                        <b style={styles.spaceFirst}>ioBroker-Projekt</b>
                        , inklusiv diese Web-Seite.
                        Das Projekt als solches sammelt Informationen über die Benutzer, ohne diese mit den Personen bzw. den Benutzerkonten zu verknüpfen und/oder an dritte weiter zu geben.
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Verbindung zu deiner ioBroker-Instanz.
                    </Typography>
                    <Typography paragraph>
                        Angabe einer gültigen
                        <b style={styles.spaces}>E-Mail-Adresse</b>
                        ist obligatorisch.
                        <br />
                        Alle anderen Informationen sind optional und können für interne Statistiken verwendet werden.
                        <br />
                        <br />

                        Die E-Mail-Adresse ist ausschließlich für die Passwortwiederherstellung erforderlich.
                        <br />
                        Persönlichen Daten oder E-Mail-Adressen werden an nicht an Dritte weitergegeben.
                        <br />
                        Die gesammelten Daten werden für die Erstellung von Nutzungsstatistiken (z.B. Anzahl der Benutzer pro Tag oder pro Woche) der jeweiligen lokalen Installation von ioBroker herangezogen.
                        <br />
                        <br />

                        Optional kann die
                        <b style={styles.spaces}>Lieferadresse</b>
                        für die Rechnung angefordert werden, sie wird jedoch nur dafür verwendet und nicht an andere Parteien weitergegeben.
                        <br />
                        <br />

                        Zu jeder ioBroker-Installation wird beim ersten Start eine zufällige interne ID/Kennung (UUID) erzeugt.
                        <br />
                        Es ist im Nachgang nicht mehr möglich, anhand der generierten ID (UUID) Rückschlüsse auf Persönliche Daten herzustellen.
                        <br />
                        Der Generierungsalgorhytmus kann
                        <a style={styles.spaces} href="https://github.com/ioBroker/ioBroker.js-controller/blob/master/lib/tools.js#L296" target="_blank" rel="noreferrer">hier</a>
                        eingesehen werden.
                        <br />
                        <br />

                        In den gesammelten Daten ist als Zuordnungskriterium lediglich die erstellte ID enthalten.
                        <br />
                        Somit sind keine Rückschlüsse auf Personen oder Orte möglich.
                        <br />
                        Welche Daten im Einzelnen übermittelt werden, ist in den Systemeinstellungen, Unterpunkt Statistiken, jeder einzelnen lokalen ioBroker-Installation einzusehen/stellen.
                        <br />
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Verbindung zum Alexa-Dienst von Amazon/Google Home oder anderen Diensten.
                    </Typography>
                    <Typography paragraph>
                        Die Fähigkeiten
                        <b style={styles.spaces}>ioBroker.cloud</b>
                        oder
                        <b style={styles.spaces}>ioBroker.iot Alexa</b>
                        sind auf jedem Gerät verfügbar, das den Alexa Voice Service unterstützt, z. B. Amazon Echo.
                        <br />
                        <br />

                        Bitte die Datenschutzbestimmungen Ihres Geräts (oder Dienstes), sowie die Datenschutzbestimmungen von Amazon für Alexa oder entsprechenden Dienst beachten.
                        Nur dort kann nachgelesen werden, welche Informationen diese Geräte und Dienste bei der Verwendung von Skills sammeln können.
                        <br />
                        <br />

                        Der ioBroker.cloud Alexa Skill oder ioBroker Google Home Skill hat keinen Zugriff auf Informationen, die von diesen Parteien gesammelt wurden. Und übernimmt daher keine Haftung für den Gebrauch dieser Geräte und der allgemeinen Nutzung von Amazon-Service oder Google Assistant.
                        <br />
                        <br />
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Analysefunktionen
                    </Typography>
                    <Typography paragraph>
                        Wir verwenden
                        <a style={styles.spaceFirst} href="https://policies.google.com/terms">Google Analytics</a>
                        und
                        <a style={styles.spaceFirst} href="https://www.facebook.com/business/m/privacy-and-data">Facebook Pixel</a>
                        .
                        Für die Bezahlung wird der Dienst
                        <a style={styles.spaces} href="https://www.paypal.com/webapps/mpp/ua/privacy-full">PayPal</a>
                        verwendet.

                        Die Datenschutzerklärung entnehmen Sie bitte den jeweiligen Websites.
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        ioBroker Visu Android und iOS Apps
                    </Typography>
                    <Typography paragraph>
                        Der folgende Abschnitt bezieht sich auf die Android-App &quot;ioBroker Visu&quot; des Entwicklers &quot;Denis Haev&quot; und die iOS-App &quot;ioBroker Visu&quot; von &quot;ioBroker GmbH&quot;.
                        <ul>
                            <li>
                                1.
                                <b> Datensammlung: </b>
                                Die ioBroker Visu App sammelt keine Daten von ihren Benutzern.
                            </li>
                            <li>
                                2.
                                <b> Datenübertragung mit einem ioBroker Pro Account: </b>
                                Sollten Sie sich entscheiden, einen
                                ioBroker Pro Account in Verbindung mit der ioBroker Visu App zu nutzen, erfolgt jegliche
                                Datenübertragung mit höchsten Sicherheitsmaßnahmen. Es ist wichtig zu betonen, dass
                                Daten, die unter diesen Umständen übertragen werden, weder von ioBroker Cloud
                                gespeichert noch aufbewahrt werden. Stattdessen werden sie ausschließlich von der
                                ioBroker Cloud verarbeitet, um eine nahtlose Kommunikation mit Ihrer lokalen
                                ioBroker-Instanz zu ermöglichen und die Integrität Ihrer Privatsphäre zu gewährleisten.
                            </li>
                            <li>
                                3.
                                <b> Keine Datenübertragung ohne ioBroker Pro Account: </b>
                                Ohne einen mit Ihrer App
                                verknüpften ioBroker Pro Account findet keinerlei Datenübertragung statt.
                            </li>
                            <li>
                                4.
                                <b> Standortdaten: </b>
                                Wenn Sie keine Geofence-Standorte verwenden, benötigt die App nur dann
                                Zugriff auf Standortdaten, um zu erkennen, ob Sie bei Verbindung mit einem bestimmten
                                WLAN unterschiedliche Zugriffsmethoden festlegen möchten. In diesem Fall werden keine
                                Standortdaten übertragen oder gespeichert.
                                <br />
                                Wenn Sie in der App mindestens einen Geofence-Standort konfigurieren, benötigt die App
                                die Berechtigung, im Hintergrund auf Standortdaten zuzugreifen, um zuverlässig zu
                                funktionieren. In diesem Fall werden Daten sicher über die ioBroker Pro Cloud an Ihre
                                verbundene lokale Installation übertragen. Daten werden nur zu diesem Zweck übertragen
                                und es werden keine Daten auf einem Server gespeichert.
                            </li>
                        </ul>
                        <br />
                        Wir überprüfen und verfeinern kontinuierlich unsere Datenschutzpraktiken, um die höchsten
                        Standards zu wahren und regulatorischen Verpflichtungen nachzukommen. Durch die Nutzung der
                        ioBroker Visu App stimmen Sie implizit der Sammlung und Nutzung Ihrer Informationen gemäß dieser
                        Datenschutzrichtlinie zu. Wenn Sie Bedenken bezüglich des Datenschutzes haben, kontaktieren Sie
                        bitte
                        <a
                            href="mailto:info@iobroker.net"
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginLeft: 4 }}
                        >
                            info@iobroker.net
                        </a>
                        .
                    </Typography>
                </Typography>
            </>;
            break;

        case 'ru':
            text = <>
                <Typography sx={theme => theme.containerTitle[props.style]}>Политика конфиденциальности.</Typography>
                <Typography variant="body1" component="div">
                    <Typography variant="h5" style={styles.title2}>
                        Политика конфиденциальности ioBroker.cloud и ioBroker.iot
                    </Typography>
                    <Typography paragraph>
                        Мы ценим вашу конфиденциальность, поэтому хотим четко объяснить, какие личные данные используются при взаимодействии с
                        <b style={styles.spaceFirst}>ioBroker.cloud</b>
                        .
                        <br />
                        <br />
                        Это заявление о защите данных распространяется на весь проект
                        <b style={styles.spaceFirst}>ioBroker</b>
                        , включая эту страницу.
                        Проект как таковой собирает информацию о пользователях, не привязывая ее к лицам или учетным записям пользователей и / или не передавая ее третьим лицам.
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Подключение к вашей инстанции ioBroker.
                    </Typography>
                    <Typography paragraph>
                        Действительный
                        <b style={styles.spaces}>адрес электронной почты</b>
                        является обязательным.
                        <br />
                        Вся остальная информация не является обязательной и может использоваться для внутренней статистики.
                        <br />
                        <br />

                        Адрес электронной почты требуется только для восстановления пароля.
                        <br />
                        Личные данные или адреса электронной почты не будут переданы третьим лицам.
                        <br />
                        Собранные данные используются для создания статистики использования (например, количества пользователей в день или в неделю) для соответствующей локальной установки ioBroker.
                        <br />
                        <br />

                        <b style={styles.spaceLast}>Адрес доставки</b>
                        может быть дополнительно запрошен для счета, но он будет использоваться только для этой цели и не будет передан другим сторонам.
                        <br />
                        <br />

                        Случайный внутренний идентификатор/идентификатор (UUID) генерируется для каждой инстанции ioBroker при ее первом запуске.
                        <br />
                        Впоследствии больше невозможно делать выводы о личных данных на основе сгенерированного идентификатора (UUID).
                        <br />
                        Алгоритм генерации можно посмотреть
                        <a style={styles.spaceFirst} href="https://github.com/ioBroker/ioBroker.js-controller/blob/master/lib/tools.js#L296" target="_blank" rel="noreferrer">здесь</a>
                        .
                        <br />
                        <br />

                        Единственным критерием назначения в собранных данных является созданный идентификатор.
                        <br />
                        Это означает, что нельзя делать никаких выводов о людях или местах.
                        <br />
                        Какие данные передаются в деталях, можно просмотреть / установить в системных настройках, подпункте статистики каждой отдельной локальной установки ioBroker.
                        <br />
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Подключение к сервису Alexa от Amazon / Google или другим сервисам.
                    </Typography>
                    <Typography paragraph>
                        Возможности
                        <b style={styles.spaces}>ioBroker.cloud</b>
                        или
                        <b style={styles.spaces}>ioBroker.iot Alexa</b>
                        доступны на любом устройстве, поддерживающем голосовую службу Alexa, например Amazon Echo.
                        <br />
                        <br />

                        Обратите внимание на правила защиты данных вашего устройства или сервиса, а также правила защиты данных Amazon для Alexa.
                        Это единственное место, где вы можете прочитать, какую информацию могут собирать эти устройства и службы при использовании навыков.
                        <br />
                        <br />

                        ioBroker.cloud Alexa Skill или ioBroker Google Home Skill не имеет доступа к информации, полученной от этих сторон.
                        И поэтому не несет ответственности за использование этих устройств и общее использование сервиса Amazon/Google Assistant или других сторонних сервисов.
                        <br />
                        <br />
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Функции аналитики посещений
                    </Typography>
                    <Typography paragraph>
                        Мы используем
                        <a style={styles.spaces} href="https://policies.google.com/terms">Google Analytics</a>
                        и
                        <a style={styles.spaceFirst} href="https://www.facebook.com/business/m/privacy-and-data">Facebook Pixel</a>
                        .
                        Для оплаты используется сервис
                        <a style={styles.spaceFirst} href="https://www.paypal.com/webapps/mpp/ua/privacy-full">PayPal</a>
                        .

                        Ознакомитесь на соответствующих сайтах с политикой конфиденциальности.
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Приложения ioBroker Visu для Android и iOS
                    </Typography>
                    <Typography paragraph>
                        Следующий раздел относится к Android-приложению &quot;ioBroker Visu&quot; от разработчика &quot;Denis Haev&quot; и iOS-приложению &quot;ioBroker Visu&quot; от &quot;ioBroker GmbH&quot;.
                        <ul>
                            <li>
                                1.
                                <b> Сбор данных: </b>
                                Приложение ioBroker Visu не собирает никаких данных от пользователей.
                            </li>
                            <li>
                                2.
                                <b> Передача данных с использованием учетной записи ioBroker Pro: </b>
                                Если вы решите использовать учетную запись ioBroker Pro в сочетании с приложением
                                ioBroker Visu, любая передача данных осуществляется с наивысшими мерами безопасности.
                                Важно подчеркнуть, что данные, передаваемые в таких обстоятельствах, ни в коем случае не
                                хранятся и не сохраняются облаком ioBroker. Вместо этого они исключительно
                                обрабатываются облаком ioBroker для обеспечения беспрепятственной связи с вашим
                                локальным экземпляром ioBroker, обеспечивая целостность вашей конфиденциальности.
                            </li>
                            <li>
                                3.
                                <b> Отсутствие передачи данных без учетной записи ioBroker Pro: </b>
                                В отсутствие связанной с вашим приложением учетной записи ioBroker Pro никакой передачи
                                данных не происходит.
                            </li>
                            <li>
                                4.
                                <b> Данные о местоположении: </b>
                                Если вы не используете геозоны, приложение нуждается в
                                доступе к данным о местоположении только для того, чтобы определить, хотите ли вы
                                указать разные методы доступа при подключении к определенной сети Wi-Fi. В этом случае
                                данные о местоположении не передаются и не хранятся.
                                <br />
                                Если вы настраиваете хотя бы одну геозону в приложении, приложению требуется разрешение
                                на доступ к данным о местоположении в фоновом режиме для обеспечения надежной работы. В
                                этом случае данные надежно передаются через ioBroker Pro Cloud на вашу локальную
                                установку. Данные передаются только для этой цели, и никакие данные не хранятся на
                                каком-либо сервере.
                            </li>
                        </ul>
                        <br />
                        Мы постоянно проверяем и совершенствуем наши практики конфиденциальности, чтобы соблюдать самые
                        высокие стандарты и соответствовать регуляторным обязательствам. Используя приложение ioBroker
                        Visu, вы неявно соглашаетесь на сбор и использование ваших данных в соответствии с настоящей
                        Политикой конфиденциальности. Если у вас есть какие-либо вопросы о конфиденциальности,
                        пожалуйста, свяжитесь с
                        <a
                            href="mailto:info@iobroker.net"
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginLeft: 4 }}
                        >
                            info@iobroker.net
                        </a>
                        .
                    </Typography>
                </Typography>
            </>;
            break;

        default:
            text = <>
                <Typography sx={theme => theme.containerTitle[props.style]}>Privacy Policy.</Typography>
                <Typography variant="body1" component="div">
                    <Typography variant="h5" style={styles.title2}>
                        ioBroker.cloud & ioBroker.iot Privacy Policy
                    </Typography>
                    <Typography paragraph>
                        We value your privacy, so we want to be explicit about what kind of personal information is involved when you interact with ioBroker.cloud.
                        <br />
                        <br />
                        This page is for the
                        <b style={styles.spaces}>ioBroker</b>
                        project as a whole, including this website. The project as such doesn&apos;t collect explicit any personally identifying information from users.
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Connection to your ioBroker instance.
                    </Typography>
                    <Typography paragraph>
                        <b style={styles.spaceLast}>Only email address</b>
                        is mandatory. All other information is optional and could be used for internal statistics.
                        <br />
                        <br />

                        Email address is required for password recovery and nothing more. No private information or email addresses will be given to other parties.
                        <br />
                        <br />

                        Optionally, the
                        <b style={styles.spaces}>delivery address</b>
                        can be stored for the invoice. It is only used for invoices and will not be given to other parties.
                        <br />
                        <br />

                        These are used in generating usage statistics about the number of unique users per day or per week of the corresponding instance of ioBroker.cloud. These IDs are generated in such a way that it is not possible to determine the identity of the device from the ID.
                        <br />
                        <br />

                        We do not collect or send any personally identifiable data from your device.
                        <br />
                        <br />

                        The exact mechanisms for generating the ID are as follows: get user name and add random UUID to it.
                        <br />
                        <br />

                        You can check the UUID generation algorithm
                        <a style={styles.spaceFirst} href="https://github.com/ioBroker/ioBroker.js-controller/blob/master/lib/tools.js#L296" target="_blank" rel="noreferrer">here</a>
                        .
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Connection to Alexa service from Amazon / Google Home or other services.
                    </Typography>
                    <Typography paragraph>
                        The ioBroker.cloud or ioBroker.iot Alexa skills are available on any device that supports the Alexa Voice Service, such as the Amazon Echo.
                        <br />
                        <br />

                        Please refer to your device&apos;s (or service) privacy policy, as well
                        <b style={styles.spaces}>Amazon’s privacy policy</b>
                        for Alexa (or corresponding for this service company),
                        to better understand what information those devices and services may be collecting when any skill is used (ioBroker.cloud or other ioBroker Alexa skills do not have access to any information collected by these parties).
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        Analytics function
                    </Typography>
                    <Typography paragraph>
                        We use
                        <a style={styles.spaces} href="https://policies.google.com/terms">Google Analytics</a>
                        and
                        <a style={styles.spaceFirst} href="https://www.facebook.com/business/m/privacy-and-data">Facebook Pixel</a>
                        .
                        The
                        <a style={styles.spaces} href="https://www.paypal.com/webapps/mpp/ua/privacy-full">PayPal</a>
                        service is used for payment.

                        Please refer to the respective websites for the privacy policy.
                    </Typography>
                    <Typography variant="h5" style={styles.title2}>
                        ioBroker Visu Android and iOS App
                    </Typography>
                    <Typography paragraph>
                        The following section refers to the Android app &quot;ioBroker Visu&quot; from the
                        developer &quot;Denis Haev&quot; and the iOS app &quot;ioBroker Visu&quot; from &quot;ioBroker
                        GmbH&quot;.
                        <ul>
                            <li>
                                1.
                                <b> Data Collection: </b>
                                The ioBroker Visu App does not engage in any form of data
                                collection from its users.
                            </li>
                            <li>
                                2.
                                <b> Data Transmission with ioBroker Pro Account: </b>
                                Should you opt to utilize an
                                ioBroker Pro account in conjunction with the ioBroker Visu App, any data transmission is
                                conducted with the utmost security measures in place. It's pertinent to emphasize that
                                data transmitted under these circumstances is neither stored nor retained by ioBroker
                                Cloud. Instead, it is exclusively processed by the ioBroker Cloud to facilitate seamless
                                communication with your local ioBroker instance, ensuring the integrity of your privacy.
                            </li>
                            <li>
                                3.
                                <b> No Data Transmission without ioBroker Pro Account: </b>
                                In the absence of an
                                ioBroker Pro account linked to your app, no data transmission takes place whatsoever.
                            </li>
                            <li>
                                4.
                                <b> Location data: </b>
                                If you don't use Geofence locations, the app only needs access
                                to location data to detect if you want to specify different access methods if connected
                                to a specific WiFi or not. No location data is transmitted or stored in this case.
                                <br />
                                If you configure at least one Geofence location in the app, the app needs permission to
                                access location data while running in background to work reliable. In this case data is
                                transmitted securely via the ioBroker Pro Cloud to your connected local installation.
                                Data is only transmitted for this purpose and no data is stored on any server.
                            </li>
                        </ul>
                        <br />
                        We continually review and refine our privacy practices to uphold the highest standards and
                        comply with regulatory obligations. By utilizing the ioBroker Visu App, you implicitly consent
                        to the collection and utilization of your information as delineated in this Privacy Policy. If
                        you have any privacy concerns, please contact
                        <a
                            href="mailto:info@iobroker.net"
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginLeft: 4 }}
                        >
                            info@iobroker.net
                        </a>
                        .
                    </Typography>
                </Typography>
            </>;
            break;
    }

    return <Box
        sx={theme => ({
            ...theme.container[props.style],
            padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
        })}
    >
        {text}
    </Box>;
}
