import React, { useEffect } from 'react';
import moment from 'moment';

import {
    Grid,
    Box,
    Tabs,
    Tab,
    Paper,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';

import PriceCard from './PriceCard';

import MainContext from '../../MainContext';
import { apiGetProducts, apiReadLicenses, apiReadSubscriptions } from '../../api';
import __ from '../../utils/i18n';
import getProductImage from '../../utils/getProductImage';
import { MOBILE_WIDTH, ORDER_TIME_LIMIT } from '../../utils/consts';

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const styles = {
    tabs: {
        background: 'white',
    },
    tabsPaper: {
        width: '100%',
    },
    page: {
        width: '100%',
        paddingTop: 20,
        height: 'calc(100% - 48px)',
        overflow: 'auto',
    },
    tabIcon: {
        width: 24,
        height: 24,
    },
    filter: {
        width: 200,
        marginLeft: 30,
        marginTop: 8,
    },
    groupIcon: {
        height: 32,
        width: 'auto',
        marginBottom: 0,
        marginRight: 8,
    },
};

export default function Pricing(props) {
    const context = React.useContext(MainContext);
    const [products, setProducts] = React.useState(null);
    const [groups, setGroups] = React.useState(null);
    const [licenses, setLicenses] = React.useState(null);
    const [remoteValidTill, setRemoteValidTill] = React.useState(null);
    const [assistantValidTill, setAssistantValidTill] = React.useState(null);

    // read products
    if (products === null) {
        setProducts(false);
        apiGetProducts()
            .then(data => {
                // detect groups
                const _groups = [];
                data.forEach(item => {
                    const parts = item.name.split('_');
                    item.group = parts[0];
                    const parts1 = item.group.split('.');
                    if (parts1.length > 2) {
                        parts1.pop();
                        item.group = parts1.join('.');
                    }

                    if (!_groups.includes(item.group)) {
                        _groups.push(item.group);
                    }
                });
                setProducts(data);
                setGroups(_groups);
            })
            .catch(error => context.snackbar(__('Cannot read: %s', __(error)), 'error', true));
    }

    // licenses
    if (licenses === null && context.theme === 'net') {
        setLicenses([]);
        apiReadLicenses()
            .then(result => setLicenses(result || []))
            .catch(error => {
                if (error !== 'Authentication required.') {
                    context.snackbar(__('Cannot read: %s', __(error)), 'error', true);
                }
            });
    }

    // subscriptions
    if (remoteValidTill === null && context.theme === 'pro') {
        setRemoteValidTill(false);

        apiReadSubscriptions()
            .then(result => {
                result && result.forEach(item => {
                    item.stillValid = !item.validTill || item.validTill === '0000-00-00 00:00:00' || new Date(item.validTill).getTime() > Date.now();
                    item.activeTillMs = item.validTill && item.validTill !== '0000-00-00 00:00:00' ? new Date(item.validTill).getTime() : null;
                    item.activeTill = item.activeTillMs ? moment(new Date(item.activeTillMs)).format('D.MM.YYYY HH:mm') : '';
                    item.time = item.time ? moment(item.time).format('D.MM.YYYY HH:mm') : '';
                });

                // find validTill Remote and validTill assistant
                let _remoteValidTill = Date.now();
                let _assistantValidTill = Date.now();
                result && result.filter(item => item.stillValid && item.activeTillMs).forEach(item => {
                    if (item.product.startsWith('remote.')) {
                        if (_remoteValidTill < item.activeTillMs) {
                            _remoteValidTill = item.activeTillMs;
                        }
                    } else if (item.product.startsWith('assistant.')) {
                        if (_assistantValidTill < item.activeTillMs) {
                            _assistantValidTill = item.activeTillMs;
                        }
                    }
                });

                if (_remoteValidTill > _assistantValidTill) {
                    _assistantValidTill = _remoteValidTill;
                }
                setRemoteValidTill(_remoteValidTill);
                setAssistantValidTill(_assistantValidTill);
            })
            .catch(error => {
                if (error !== 'Authentication required.') {
                    context.snackbar(__('Cannot read: %s', __(error)), 'error', true);
                }
            });
    }

    const types = context.theme === 'net' ? [
        {
            name: 'commercial',
            title: 'Commercial licenses',
            icon: 'products/commercial.svg',
        },
        {
            name: 'community',
            title: 'Community licenses',
            icon: 'products/community.svg',
        },
    ] : [
        {
            name: 'remote',
            title: 'With remote access',
            icon: getProductImage('remote.'),
        },
        {
            name: 'assistant',
            title: 'Only smart assistants',
            icon: getProductImage('assistant.'),
        },
        // {
        //     name: 'link',
        //     title: 'Link licenses',
        //     icon: 'products/link.png',
        // },
    ];

    const location = (window.location.hash || `#${types[0].name}`).substring(1).split('/');
    let startTab = location[0];
    if (!types.find(item => item.name === startTab)) {
        startTab = types[0].name;
    }

    const [typeTab, setTypeTab] = React.useState(startTab);

    const startGroupFilter = location[1] || window.localStorage.getItem('groupFilter') || '_';
    const [groupFilter, setGroupFilter] = React.useState(startGroupFilter);

    useEffect(() => {
        // set location
        const hash = typeTab === 'commercial' ? `#${startTab}${groupFilter === '_' ? '' : `/${groupFilter}`}` : `#${startTab}`;
        if (document.location.hash !== hash) {
            document.location.hash = hash;
        }
    }, [groupFilter, startTab, typeTab]);

    if (!products) {
        return null;
    }

    const NOW_IN_TWO_YEARS = Date.now() + ORDER_TIME_LIMIT;

    let maxBest = 0;

    const cards = products
        .filter(product => {
            if (product.best > maxBest) {
                maxBest = product.best;
            }

            if (typeTab === 'commercial' && groupFilter && groupFilter !== '_') {
                if (product.group !== groupFilter) {
                    return false;
                }
            }

            if (context.theme === 'net') {
                // check if the license may be bought
                if (typeTab === 'community') {
                    return product.name.includes('.offline') || !product.price;
                }
                if (product.name.startsWith('link.') && typeTab === 'link') {
                    return true;
                }

                return !product.name.includes('.offline') && !!product.price;
            }

            return product.type === typeTab;
        })
        .map(product => {
            if (product.best < maxBest) {
                product.best = 0;
            }
            return product;
        })
        .sort((a, b) => {
            const _a = a.localData ? a.localData.order || 1000 : 1000;
            const _b = b.localData ? b.localData.order || 1000 : 1000;
            if (_a > _b) {
                return 1;
            }
            if (_a < _b) {
                return -1;
            }
            return 0;
        })
        .map(product => {
            const additionalListItems = [];
            let orderEnabled = true;
            let explanationOfDisability = '';
            if (context.theme === 'net') {
                if (!product.price || product.name.includes('.offline')) {
                    additionalListItems.push({ title: __('Only for private use'), check: true });
                } else if (!product.localData || !product.localData.hideCommercial) {
                    additionalListItems.push({ title: __('For commercial use'), check: true });
                }
                if (licenses && (product.name.includes('.offline') || !product.price) && licenses.find(item => item.product === product.name && item.invoice === 'free')) {
                    orderEnabled = false;
                    explanationOfDisability = __('You can have only one community license of each type');
                }
            } else if (remoteValidTill && product.name.startsWith('remote.') && remoteValidTill > NOW_IN_TWO_YEARS) {
                orderEnabled = false;
                explanationOfDisability = `${__('Your subscription is active for more than 1.1 years and ends on')} ${new Date(remoteValidTill).toLocaleDateString()}`;
            } else if (assistantValidTill && product.name.startsWith('assistant.') && assistantValidTill > NOW_IN_TWO_YEARS) {
                orderEnabled = false;
                explanationOfDisability = `${__('Your subscription is active for more than 1.1 years and ends on')} ${new Date(assistantValidTill).toLocaleDateString()}`;
            }

            return <PriceCard
                key={`${product.name}_${product.price || 0}`}
                product={product}
                style={props.style}
                additionalListItems={additionalListItems}
                orderEnabled={orderEnabled}
                explanationOfDisability={explanationOfDisability}
            />;
        });

    const wHeight = document.body.clientHeight;

    const tabs = types.map((type, i) =>
        <Tab
            value={type.name}
            key={`${type.name}_${i}`}
            wrapped
            icon={type.icon && wHeight > 600 ? <img src={type.icon} style={styles.tabIcon} alt={type.name} /> : null}
            label={__(type.title)}
            {...a11yProps(type.name)}
        />);

    const filterGroupComponent = groups && typeTab === 'commercial' ?
        <FormControl style={styles.filter} variant="standard">
            <InputLabel>{__('Product filter')}</InputLabel>
            <Select
                variant="standard"
                value={groupFilter}
                onChange={event => {
                    if (event.target.value === '_') {
                        window.localStorage.removeItem('groupFilter');
                    } else {
                        window.localStorage.setItem('groupFilter', event.target.value);
                    }
                    window.location.hash = `#${typeTab}/${event.target.value}`;
                    setGroupFilter(event.target.value);
                }}
            >
                <MenuItem value="_">{__('all')}</MenuItem>
                {groups.map((group, i) => <MenuItem value={group}>
                    <img
                        key={`${group}_${i}`}
                        src={getProductImage(group)}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = 'products/default.png';
                        }}
                        style={styles.groupIcon}
                        alt={group}
                    />
                    {group}
                </MenuItem>)}
            </Select>
        </FormControl> : null;

    return <Box
        sx={theme => ({
            ...theme.container[props.style],
            width: window.document.body.clientWidth >= MOBILE_WIDTH ? 'calc(100% - 100px)' : 'calc(100% - 10px)',
            height: 'calc(100% - 20px)',
            overflow: 'hidden',
            padding: window.document.body.clientWidth >= MOBILE_WIDTH ? '10px 50px' : '5px 5px',
        })}
    >
        <Paper square style={styles.tabsPaper}>
            <Tabs
                variant="fullWidth"
                value={typeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event, newValue) => {
                    window.location.hash = `#${newValue}`;
                    setTypeTab(newValue);
                }}
                style={styles.tabs}
            >
                {tabs}
            </Tabs>
        </Paper>
        {filterGroupComponent}
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ ...styles.page, paddingTop: filterGroupComponent ? 0 : 20 }}
        >
            {cards}
        </Grid>
    </Box>;
}
