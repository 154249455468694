import React from 'react';
import LoginForm from './LoginForm';

export default function LoginFormTab(props) {
    const { index } = props;

    return <div
        role="tabpanel"
        id="simple-tabpanel-0"
        aria-labelledby="simple-tab-0"
        hidden={index !== 0}
    >
        <LoginForm />
    </div>;
}
