import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import {
    Button,
    ButtonGroup,
    IconButton,
} from '@mui/material';
import {
    PanTool as PanToolIcon,
    Policy as PolicyIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import __ from '../../utils/i18n';
import { MOBILE_WIDTH } from '../../utils/consts';

const styles = {
    menuButton: {
        padding: '13px 5px',
        borderRadius: 0,
    },
};

export default function BottomMenu(props) {
    const [width, setWidth] = React.useState(document.body.clientWidth);
    const updateWindowDimensions = () => setWidth(document.body.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        updateWindowDimensions();

        // returned function will be called on component unmount
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    return <ButtonGroup sx={theme => theme.bottomMenu[props.style]}>
        {props.showOpenButton && !props.menuOpened ?
            <IconButton
                title={__('Show menu')}
                onClick={() => props.onMenuOpen()}
                style={styles.menuButton}
                sx={theme => theme.bottomMenuButton[props.style]}
            >
                <ChevronRightIcon />
            </IconButton> : null}
        <Button style={{ flexGrow: 1 }} disabled />
        <Button
            startIcon={<PolicyIcon />}
            component={NavLink}
            title={width < MOBILE_WIDTH ? __('Imprint') : ''}
            to="/www/imprint"
            classes={{ active: 'Mui-selected' }}
            sx={theme => theme.bottomMenuButton[props.style]}
        >
            {width >= MOBILE_WIDTH ? __('Imprint') : ''}
        </Button>
        <Button
            startIcon={<PanToolIcon />}
            component={NavLink}
            title={width < MOBILE_WIDTH ? __('Privacy') : ''}
            to="/www/policy"
            classes={{ active: 'Mui-selected' }}
            sx={theme => theme.bottomMenuButton[props.style]}
        >
            {width >= MOBILE_WIDTH ? __('Privacy') : ''}
        </Button>
    </ButtonGroup>;
}
