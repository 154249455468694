import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';

// eslint-disable import/no-cycle
import { apiLogout, PORT, apiGetUserProfile } from '../api';
import i18n, { getLang, setLang } from '../utils/i18n';

const MainContext = React.createContext({
    updateUser: (/* force */) => Promise.resolve(),
    user: null,
    theme: 'net',
    updateTheme: null,
    i18n: null,
    snackbar: null,
    loginRedirect: null,
    setLoginRedirect: null,
    port: PORT,
    requestingUser: true,
});

export default MainContext;

export const ContextConsumer = MainContext.Consumer;

const CLOUD = window.location.hostname === 'iobroker.pro' ? 'pro' : 'net';
export { CLOUD };

export function MainContextProvider(props) {
    const [loginRedirect_, setLoginRedirect_] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [userRequested, setUserRequested] = React.useState(false);
    const [language, setLanguage] = React.useState(getLang());
    const { enqueueSnackbar } = useSnackbar();

    const snackbar = (text, variant, noTranslation) => {
        enqueueSnackbar(noTranslation ? text : i18n(text), {
            variant,
            preventDuplicate: true,
            autoHideDuration: 3000,
            // maxSnack: 5,
            disableWindowBlurListener: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
    };

    const updateUser = force => {
        if (typeof force === 'object') {
            setUser(force);
            return Promise.resolve(force);
        }
        return apiGetUserProfile(force === true)
            .then(data => {
                setUser(data);
                return data;
            })
            .catch(error => {
                setUser(false);
                return Promise.reject(error);
            });
    };

    if (!userRequested) {
        setUserRequested(true);
        updateUser(true)
            .catch(err => console.warn(err));
    }

    const context = useMemo(() => ({
        user,
        lang: language,
        setLang: l => {
            setLang(l);
            setLanguage(l);
        },
        updateUser,
        logoutUser: () => apiLogout()
            .catch(() => {})
            .then(() => setUser(false)),
        theme: CLOUD,
        i18n,
        snackbar,
        loginRedirect: loginRedirect_,
        setLoginRedirect: url => {
            console.log(`Redirect: ${url}`);
            setLoginRedirect_(url);
        },
        port: PORT,
    }), [user, language, loginRedirect_, updateUser, i18n, snackbar]);

    return <MainContext.Provider
        value={context}
    >
        {props.children}
    </MainContext.Provider>;
}
