import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';

import MainContext from '../../MainContext';
import { apiEmailConfirm } from '../../api';
import getParam from '../../utils/getParam';
import __ from '../../utils/i18n';

export default function ConfirmedEmail() {
    const navigate = useNavigate();

    const code = getParam('code');

    const [result, setResult] = React.useState(null);

    const context = React.useContext(MainContext);

    if (result === null) {
        setResult(false);
        apiEmailConfirm(code)
            .then(() => {
                setResult(true);
                setTimeout(() => navigate('/www/login'), 4000);
            })
            .catch(error => context.snackbar(error, 'error'));
    }

    if (result === null) {
        return null;
    }

    return <Grid container alignContent="center" alignItems="center" direction="column">
        <Typography paragraph>
            <p>
                {result ?
                    __('Hurrah! Your email is confirmed. You will be redirected to your profile in a second or press here to be redirected manually.')
                    :
                    __('Unfortunately we cannot confirm your email. Please try again.')}
            </p>
        </Typography>
    </Grid>;
}
