import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// eslint-disable import/no-cycle
import Pages from './Pages';
import theme from './theme/index.js';
import { MainContextProvider } from './MainContext';
import RemoteAccess from './Pages/Account/RemoteAccess';
import Diagnostics from './Pages/Account/Diagnostics';
import Subscriptions from './Pages/Account/Subscriptions';
import Profile from './Pages/Account/Profile';
import DeleteProfile from './Pages/Account/DeleteProfile';

import Login from './Pages/Login';
import Logout from './Pages/Logout';
import ChangePassword from './Pages/ChangePassword';
import ForgotPassword from './Pages/ForgotPassword';
import ConfirmedEmail from './Pages/ConfirmedEmail';
import TryAgainConfirm from './Pages/TryAgainConfirm';
import Imprint from './Pages/Imprint';
import Contract from './Pages/Contract';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Pricing from './Pages/Pricing';
import CookiesHint from './utils/CookiesHint';

function App() {
    if (!window.location.pathname.startsWith('/www')) {
        window.location = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/www${window.location.pathname}${window.location.search}${window.location.hash}`;
    } else {
        return <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme('colored')}>
                <BrowserRouter>
                    <MainContextProvider>
                        <Pages />
                        <CookiesHint />
                    </MainContextProvider>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>;
    }
}

export default App;

export const menuMain = {
    www: {
        title: 'www',
        url: 'https://www.iobroker.net',
    },
    pricing: {
        title: 'Pricing',
        component: Pricing,
    },
};

export const routesExtended = {
    login: {
        title: 'login',
        component: Login,
    },
    logout: {
        title: 'logout',
        component: Logout,
    },
    confirmedEmail: {
        title: 'Confirmed e-mail',
        component: ConfirmedEmail,
    },
    tryAgain: {
        title: 'Try again',
        component: TryAgainConfirm,
    },
    imprint: {
        title: 'Imprint',
        component: Imprint,
    },
    policy: {
        title: 'Privacy Policy',
        component: PrivacyPolicy,
    },
    contract: {
        title: 'contract',
        component: Contract,
    },
    passwordReset: {
        title: 'Change password',
        component: ChangePassword,
    },
    forgotPassword: {
        title: 'Forgot Password',
        component: ForgotPassword,
    },
    deleteProfile: {
        title: 'Delete profile',
        component: DeleteProfile,
    },
};

export const menuPoints = {
    applications: {
        title: 'Applications',
        link: '/',
    },
    appkeys: {
        title: 'App keys',
        component: RemoteAccess,
    },
    licenses: {
        title: 'Licenses',
        component: Subscriptions,
        pro: {
            route: 'subscriptions',
            title: 'Subscriptions',
            component: Subscriptions,
        },
    },
    profile: {
        title: 'Profile',
        component: Profile,
    },
    diagnostics: {
        title: 'Diagnostics',
        component: Diagnostics,
    },
};

export const menuAVA = {
    profile: {
        title: 'Profile',
        component: Profile,
    },
};
