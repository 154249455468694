import React from 'react';

import {
    Button,
    Typography,
} from '@mui/material';

import __ from './i18n';
import { MOBILE_WIDTH } from './consts';

const styles = {
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#00000080',
        zIndex: 1300,
    },
    root: {
        boxShadow: 'rgb(18, 18, 18) 2px 2px 14px 2px',
        width: 'calc(100% - 50px)',
        position: 'absolute',
        bottom: 0,
        zIndex: 10000,
        left: 10,
        padding: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        backgroundColor: '#FFF',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 20,
    },
    btn: {
        marginTop: 20,
    },
    textDiv: {
        width: window.document.body.clientWidth >= MOBILE_WIDTH ? 'calc(100% - 150px)' : '100%',
        display: 'inline-block',
    },
    buttonDiv: {
        width: 150,
        display: 'inline-block',
        verticalAlign: 'top',
    },
};

export default function CookiesHint() {
    const [acknowledged, setAcknowledged] = React.useState(window.localStorage.getItem('cookieUsage'));

    if (acknowledged) {
        return null;
    }

    const phrases = [
        'Just like a car has at least four wheels,',
        'Just like almost all birds can fly,',
        'Just like the earth revolves around the sun,',
        'Just like everyone on the internet,',
        'Just like cats say meow,',
        'Just like the moon turns around the earth,',
    ];

    const pos = Math.round(Math.random() * phrases.length);

    return <div style={styles.background}>
        <div style={styles.root}>
            <div style={styles.textDiv}>
                <Typography style={styles.title}>
                    {__('This website uses cookies')}
                </Typography>
                <p>
                    {__(phrases[pos])}
                    &nbsp;
                    {__('we use cookies to personalize content and advertisements and to analyze access to our website.')}
                </p>
            </div>
            <div style={styles.buttonDiv}>
                <Button
                    variant="contained"
                    style={styles.btn}
                    color="primary"
                    onClick={() => {
                        window.localStorage.setItem('cookieUsage', 'acknowledged');
                        setAcknowledged('acknowledged');
                    }}
                >
                    {__('Ok')}
                </Button>
            </div>
        </div>
    </div>;
}
