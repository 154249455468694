import de from '../i18n/de.json';
import en from '../i18n/en.json';
import ru from '../i18n/ru.json';

const languages = {
    de,
    en,
    ru,
};

let lang;

export default function __(text, arg1, arg2, arg3) {
    let t;
    if (languages[lang]) {
        t = languages[lang][text] || languages.en[text];
        if (!t) {
            console.log(`Translate: ${text}`);
            t = text;
        }
    } else {
        console.log(`Translate1: ${text}`);
        t = text;
    }

    if (arg1 !== undefined) {
        t = t.replace('%s', arg1);
        if (arg2 !== undefined) {
            t = t.replace('%s', arg2);
            if (arg3 !== undefined) {
                t = t.replace('%s', arg3);
            }
        }
    }

    return t;
}

export function getLang() {
    if (!lang) {
        lang = localStorage.getItem('lang');
        lang = lang || (navigator.language ? navigator.language.substring(0, 2) : 'en');
        if (!languages[lang]) {
            lang = 'en';
        }
        console.log(`Use auto-language: ${lang}`);
    }
    return lang;
}

getLang();

export function setLang(newLang) {
    console.log(`Use language: ${newLang}`);
    localStorage.setItem('lang', newLang);
    lang = newLang;
}
